import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import AnchorTop from "../../images/anchor-top.svg"
import AnchorBottom from "../../images/anchor-bottom.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "react-dates/initialize"
import { useEffect } from "react"
import formatPrice from "../../utils/priceFormatter"
import PropTypes from "prop-types"
import BookCalendar from "../bookCalendar"
import moment from "moment"

const SelectDate = ({ data, setData, setPage, failReserv, isMobileScreen, dateError }) => {
    const [ariaExpanded, setAriaExpanded] = useState(!isMobileScreen)
    const [daysData, setDaysData] = useState([])
    const [_, setSelectedDays] = useState([])
    const [error, setError] = useState(null)

    const { vesselId, vesselName, summary, from, to, multiDay } = data

    const findSelectedDaysAndPrice = () => {
        let startDateIndex = undefined
        let endDateIndex = undefined
        let days = []

        if (to && daysData) {
            const endDateItem = daysData.find(d => d.date === to.format("YYYY-MM-DDT00:00:00"))
            endDateIndex = daysData.indexOf(endDateItem)
        }
        if (from && daysData) {
            const startDateItem = daysData.find(d => d.date === from.format("YYYY-MM-DDT00:00:00"))
            startDateIndex = daysData.indexOf(startDateItem)
            days = endDateIndex ? daysData.slice(startDateIndex, endDateIndex + 1) : [startDateItem]
            setSelectedDays(days)
        }

        //Find sum for All days
        if (days.length) {
            const freeDays = days.filter(d => !d?.booked && d?.price)
            const sum = freeDays.reduce((s, d) => s + d?.price, 0)
            setData(d => ({
                ...d,
                summary: {
                    ...d.summary,
                    boatRentalPrice: sum
                }
            }))
        }
    }

    useEffect(() => {
        findSelectedDaysAndPrice()
    }, [from, to, daysData])

    const oneDay = from?.isSame(to) || !to
    const fromTo = !from
        ? ""
        : oneDay
        ? moment(from).format("Do of MMMM YYYY")
        : `${moment(from).format("Do of MMMM YYYY")} - ${moment(to).format("Do of MMM YYYY")}`
    const salesTax = summary.boatRentalPrice * 0.21
    const totalAmount = salesTax + summary.boatRentalPrice

    return (
        <div className="container-xxl flex-grow-1 top-gradient-container">
            <div className="row h-100 position-relative overflow-hidden">
                <img src={AnchorTop} className="overlay-anchor-top-booking d-none d-md-block" alt="Anchor vector" />
                <StaticImage className="d-none d-md-block bg-your-details" src="../../images/water5.png" alt="Water Background" />
                <img src={AnchorBottom} className="overlay-anchor-bottom-booking d-none d-md-block" alt="Anchor bottom vector" />
                <div className="col-12 pt-5 col-md-7 ps-inside z-index-1">
                    <span className="breadcrumbs mb-3">Book your experience &gt; Details &gt; Yacht &gt; Date</span>
                    <h2>Select a Date</h2>
                    <p className="mb-md-4">Please use the calendar below to select your date of preference for your special day.</p>
                    {dateError && (
                        <div style={{ color: "red" }} className="mb-1">
                            {dateError}
                        </div>
                    )}
                    {failReserv && (
                        <div style={{ color: "red" }} className="mb-1">
                            {failReserv}
                        </div>
                    )}
                    {error && (
                        <div style={{ color: "red" }} className="mb-1">
                            {failReserv}
                        </div>
                    )}
                    <BookCalendar
                        daysData={daysData}
                        setDaysData={setDaysData}
                        exceptBookingId={data.id}
                        from={from}
                        to={to}
                        setData={setData}
                        setError={setError}
                        vesselId={vesselId}
                        multiSelect={multiDay}
                    />
                    <p className="mb-md-4">
                        Nothing available in the date you’re wanting? Go{" "}
                        <b className="text-purple" style={{ cursor: "pointer" }} onClick={() => setPage("yacht")}>
                            Back
                        </b>{" "}
                        and choose a different Yacht
                    </p>
                </div>
                <div className="col-12 mb-3 col-md-5 pt-md-5  z-index-1">
                    <div className="expense-card">
                        <div className="expense-header d-flex">
                            <div className="text-purple">Your Experience Summary</div>
                            <div className="ms-auto">
                                <button
                                    className={`button small d-md-none light-background ${ariaExpanded ? "collapsed" : ""}`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#expense"
                                    aria-expanded={ariaExpanded}
                                    aria-controls="expense"
                                    onClick={() => setAriaExpanded(!ariaExpanded)}
                                >
                                    {!ariaExpanded ? (
                                        <>
                                            <FontAwesomeIcon fixedWidth className=" " size="1x" icon={["far", "arrow-down"]} />
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon className="" fixedWidth size="1x" icon={["far", "arrow-up"]} />
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className={`collapse ${!isMobileScreen ? "show" : ""}`} id="expense">
                            <div className="expense-body">
                                <div className="mb-3">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your yacht</div>
                                        <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("yacht")}
                                            className="ms-auto expense-edit"
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    <div className="expense-value">Rental of {vesselName}</div>
                                    <div className="border-sub my-3" />
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your date</div>
                                    </div>
                                    <div className="expense-value">{fromTo}</div>
                                </div>
                            </div>
                            <div className="expense-footer">
                                <div className="d-flex mb-2">
                                    <div className="expense-label-footer">Boat Rental</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(summary.boatRentalPrice)}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="expense-label-footer">Sales Tax (21%)</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(salesTax)}</div>
                                </div>
                                <div className="border-total my-3" />
                                <div className="d-flex mb-2">
                                    <div className="expense-total-label">Total Amount so far</div>
                                    <div className="ms-auto expense-total-value">€{formatPrice(totalAmount)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SelectDate.propTypes = {
    data: PropTypes.object.isRequired,
    setData: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    failReserv: PropTypes.string
}

export default SelectDate
