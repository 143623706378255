import { getBaseUrl } from "./helpers"

const TEXT_PLAIN_HEADER = "text/plain; charset=utf-8"

const getContentType = response => response.headers.get("Content-Type")

const fetchQuery = async ({ method, endPoint, body, toJSON = true, successCallback = () => {}, failCallback = () => {} }) => {
    const token = localStorage.getItem("token")

    const requestOptions = {
        method,
        headers: { "Content-Type": "application/json", Authorization: token ? `Bearer ${token}` : undefined },
        body: body ? JSON.stringify(body) : undefined
    }

    let url = `${getBaseUrl()}${endPoint}`
    let response = undefined

    try {
        response = await fetch(url, requestOptions)

        if (response.ok) {
            const contentType = getContentType(response)

            if (toJSON && contentType !== TEXT_PLAIN_HEADER) {
                response = await response.json()
            }
            successCallback(response)
        } else {
            if (response.status === 401 && typeof window !== "undefined") {
                window.localStorage.removeItem("token")
                window.location.href = "/signin"
            } else {
                failCallback(response)
            }
        }
    } catch (error) {
        failCallback(error)
    }

    return response
}

export default fetchQuery
