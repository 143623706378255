import { StaticImage } from "gatsby-plugin-image"
import React, { useRef, useState } from "react"
import AnchorTop from "../../images/anchor-top.svg"
import AnchorBottom from "../../images/anchor-bottom.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import formatPrice from "../../utils/priceFormatter"
import PropTypes from "prop-types"
import CheckoutForm from "./checkoutForm"
import countries from "../../utils/countries"
import { Overlay, Popover } from "react-bootstrap"
import { compressToEncodedURIComponent } from "lz-string"
import moment from "moment"
import { Elements } from "@stripe/react-stripe-js"

const YourPaymentDetails = ({
    data,
    setData,
    setPage,
    errors,
    clientInfo,
    errorMessage,
    setErrorMessage,
    isMobileScreen,
    stripeError,
    options,
    stripePromise,
    stripeRefresh,
    refreshing
}) => {
    const [ariaExpanded, setAriaExpanded] = useState(!isMobileScreen)
    const [isAdmin, setIsAdmin] = useState(typeof window !== "undefined" && !!window?.localStorage.getItem("token"))
    const [copied, setCopied] = useState(false)
    const [loadingStripe, setLoadingStripe] = useState(true)

    const copyButtonRef = useRef()

    const { billingFirstName, billingLastName, country, city, postcode, address } = data

    const { extras, vesselName, from, to, routeName, summary } = data
    const oneDay = from?.isSame(to) || !to
    const fromTo = !from
        ? ""
        : oneDay
        ? moment(from).format("Do of MMMM YYYY")
        : `${moment(from).format("Do of MMMM YYYY")} - ${moment(to).format("Do of MMM YYYY")}`
    const allExtras = [...extras.food, ...extras.drinks, ...extras.others]
    const allSumm = summary.boatRentalPrice + summary.fuel + summary.totalExtras
    const salesTax = allSumm * 0.21
    const totalAmount = allSumm + salesTax

    const onClickCopyButton = () => {
        const dataString = JSON.stringify(data)
        const compressed = compressToEncodedURIComponent(dataString)
        const generatedLink = window.location.origin + "/book?d=" + compressed

        navigator.clipboard.writeText(generatedLink)
        setCopied(true)
    }

    return (
        <div className="container-xxl flex-grow-1 top-gradient-container">
            <div className="row h-100 position-relative overflow-hidden">
                <img src={AnchorTop} className="overlay-anchor-top-booking d-none d-md-block" alt="Anchor vector" />
                <StaticImage className="d-none d-md-block bg-your-details" src="../../images/water5.png" alt="Water Background" />
                <img src={AnchorBottom} className="overlay-anchor-bottom-booking d-none d-md-block" alt="Anchor bottom vector" />
                <div className="col-12 mb-3 pt-5 col-md-7 mb-md-0 ps-inside z-index-1">
                    <span className="breadcrumbs mb-3">
                        Book your experience &gt; Details &gt; Yacht &gt; Date &gt; Route &gt; Extras &gt; Payment
                    </span>
                    <h2>Your Payment Details</h2>
                    <p className="mb-md-4">
                        Final step, input your payment details to complete your booking. One step closer to panoramic views on your
                        Maliblue yacht.
                    </p>
                    <h3>Billing details</h3>
                    <div className="row gy-3 g-md-3 mb-3">
                        <div className="col-12 col-md-6">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    onChange={e => setData(d => ({ ...d, billingFirstName: e.target.value }))}
                                    value={billingFirstName}
                                    className={`form-control ${errors.billingFirstName ? "error" : ""}`}
                                    id="firstName"
                                    placeholder="Your first name"
                                />
                                <label htmlFor="firstName">Your first name</label>
                            </div>
                            {errors.billingFirstName && <div className="error-text">{errors.billingFirstName}</div>}
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    onChange={e => setData(d => ({ ...d, billingLastName: e.target.value }))}
                                    value={billingLastName}
                                    className={`form-control ${errors.billingLastName ? "error" : ""}`}
                                    id="lastName"
                                    placeholder="Your last name"
                                />
                                <label htmlFor="lastName">Your last name</label>
                            </div>
                            {errors.billingLastName && <div className="error-text">{errors.billingLastName}</div>}
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-floating">
                                <select
                                    className={`form-select ${errors.country ? "error" : ""}`}
                                    onChange={e => setData(d => ({ ...d, country: e.target.value }))}
                                    value={country}
                                    id="country"
                                    aria-label="Floating label select example"
                                >
                                    {countries.map(c => (
                                        <option value={c.name} key={c.name}>
                                            {c.name}
                                        </option>
                                    ))}
                                </select>
                                <label htmlFor="country">Country/Region</label>
                            </div>
                            {errors.country && <div className="error-text">{errors.country}</div>}
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    onChange={e => setData(d => ({ ...d, address: e.target.value }))}
                                    value={address}
                                    className={`form-control ${errors.address ? "error" : ""}`}
                                    id="address"
                                    placeholder="Address"
                                />
                                <label htmlFor="address">Address</label>
                            </div>
                            {errors.address && <div className="error-text">{errors.address}</div>}
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    onChange={e => setData(d => ({ ...d, city: e.target.value }))}
                                    value={city}
                                    className={`form-control ${errors.city ? "error" : ""}`}
                                    id="city"
                                    placeholder="City"
                                />
                                <label htmlFor="city">City</label>
                            </div>
                            {errors.city && <div className="error-text">{errors.city}</div>}
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    onChange={e => setData(d => ({ ...d, postcode: e.target.value }))}
                                    value={postcode}
                                    className={`form-control ${errors.postcode ? "error" : ""}`}
                                    id="postcode"
                                    placeholder="Postcode"
                                />
                                <label htmlFor="postcode">Postcode</label>
                            </div>
                            {errors.postcode && <div className="error-text">{errors.postcode}</div>}
                        </div>
                    </div>
                    <div className="border-between-payment my-3 my-md-5" />
                    <h3>Payment details</h3>
                    <div className="row gy-3 g-md-3 mb-5 ">
                        {clientInfo?.clientSecret && (
                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm
                                    setLoadingStripe={setLoadingStripe}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    clientSecret={clientInfo?.clientSecret}
                                />
                            </Elements>
                        )}

                        {((!clientInfo?.clientSecret && !stripeError) ||
                            refreshing ||
                            (loadingStripe && clientInfo?.clientSecret)) && (
                            <div className="col-12 mt-5 d-flex flex-column align-items-center justify-content-center">
                                <span className="loader mt-2"></span>
                            </div>
                        )}

                        {stripeError && (
                            <div className="col-12 mt-5 d-flex flex-column align-items-center justify-content-center">
                                <p>Stripe is unavailable now</p>
                                <button onClick={stripeRefresh} className="button fill light-background">
                                    Refresh
                                </button>
                            </div>
                        )}

                        {isAdmin && (
                            <div className="d-flex mt-5 justify-content-end">
                                <Overlay show={copied} placement="right" target={copyButtonRef.current}>
                                    <Popover id={data.id}>
                                        <Popover.Body>Copied to clipboard</Popover.Body>
                                    </Popover>
                                </Overlay>
                                <button
                                    onMouseLeave={() => setCopied(false)}
                                    onClick={onClickCopyButton}
                                    ref={copyButtonRef}
                                    className="button fill light-background"
                                >
                                    copy payment link
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-12 mb-3 col-md-5 pt-md-5  z-index-1">
                    <div className="expense-card">
                        <div className="expense-header d-flex">
                            <div className="text-purple">Your Experience Summary</div>
                            <div className="ms-auto">
                                <button
                                    className={`button small d-md-none light-background ${ariaExpanded ? "collapsed" : ""}`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#expense"
                                    aria-expanded={ariaExpanded}
                                    aria-controls="expense"
                                    onClick={() => setAriaExpanded(!ariaExpanded)}
                                >
                                    {!ariaExpanded ? (
                                        <>
                                            <FontAwesomeIcon fixedWidth className=" " size="1x" icon={["far", "arrow-down"]} />
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon className="" fixedWidth size="1x" icon={["far", "arrow-up"]} />
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className={`collapse ${!isMobileScreen ? "show" : ""}`} id="expense">
                            <div className="expense-body">
                                <div className="mb-3">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your yacht</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("yacht")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    <div className="expense-value">Rental of {vesselName}</div>
                                    <div className="border-sub my-3" />
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your date</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("date")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    <div className="expense-value">{fromTo}</div>
                                    <div className="border-sub my-3" />
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your route</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("route")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    <div className="expense-value">{routeName}</div>
                                    <div className="border-sub my-3" />
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your extras</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("extras")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    {allExtras.map(e => (
                                        <div
                                            key={e.id}
                                            className="d-flex justify-content-between align-items-center expense-value mb-1"
                                        >
                                            <div>{e.extraName}</div>
                                            {e.count !== 1 && <div>x{e.count}</div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="expense-footer">
                                <div className="d-flex mb-2">
                                    <div className="expense-label-footer">Boat Rental</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(summary.boatRentalPrice)}</div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div className="expense-label-footer">Fuel</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(summary.fuel)}</div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div className="expense-label-footer">Total Extras</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(summary.totalExtras)}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="expense-label-footer">Sales Tax (21%)</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(salesTax)}</div>
                                </div>
                                <div className="border-total my-3" />
                                <div className="d-flex mb-2">
                                    <div className="expense-total-label">Total Amount so far</div>
                                    <div className="ms-auto expense-total-value">€{formatPrice(totalAmount)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

YourPaymentDetails.propTypes = {
    data: PropTypes.object.isRequired,
    setData: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
}

export default YourPaymentDetails
