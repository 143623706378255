import React from "react"

const ArrowIcon = ({ className, color }) => (
    <svg className={className} width="12" height="12" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.84825 0.0167236L7.50018 0.613218L3.81698 3.98324L0.133789 0.613218L0.785715 0.0167236L3.81698 2.79025L6.84825 0.0167236Z"
            fill={color}
        />
    </svg>
)

export default ArrowIcon
