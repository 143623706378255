import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"
import AnchorTop from "../../images/anchor-top.svg"
import AnchorBottom from "../../images/anchor-bottom.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import formatPrice from "../../utils/priceFormatter"
import PropTypes from "prop-types"
import moment from "moment"

const DietaryRequirements = ({ foodExtras, setPage, data, setData, isMobileScreen }) => {
    const [ariaExpanded, setAriaExpanded] = useState(!isMobileScreen)

    const { extras, vesselName, from, to, routeName, summary, dietaryRequirements } = data
    const oneDay = from?.isSame(to) || !to
    const fromTo = !from
        ? ""
        : oneDay
        ? moment(from).format("Do of MMMM YYYY")
        : `${moment(from).format("Do of MMMM YYYY")} - ${moment(to).format("Do of MMM YYYY")}`
    const allExtras = [...extras.food, ...extras.drinks, ...extras.others]
    const allSumm = summary.boatRentalPrice + summary.fuel + summary.totalExtras
    const salesTax = allSumm * 0.21
    const totalAmount = allSumm + salesTax

    return (
        <div className="container-xxl flex-grow-1 top-gradient-container">
            <div className="row h-100 position-relative overflow-hidden">
                <img src={AnchorTop} className="overlay-anchor-top-booking d-none d-md-block" alt="Anchor vector" />
                <StaticImage className="d-none d-md-block bg-your-details" src="../../images/water5.png" alt="Water Background" />
                <img src={AnchorBottom} className="overlay-anchor-bottom-booking d-none d-md-block" alt="Anchor bottom vector" />
                <div className="col-12 mb-3 pt-5 col-md-7 mb-md-0 ps-inside z-index-1">
                    <span className="breadcrumbs mb-3">
                        Book your experience &gt; Details &gt; Yacht &gt; Date &gt; Route &gt; Extras
                    </span>
                    <h2>Dietary Requirements</h2>
                    <p className="mb-md-4">
                        As you’ve selected food please let us know if any one in your group has any dietary requirements. You can do
                        this by filling in the text box below.
                    </p>
                    <h3>Your Requirements</h3>
                    <div className="form-floating">
                        <textarea
                            className="form-control"
                            placeholder="Leave a comment here"
                            id="floatingTextarea"
                            style={{ height: "125px", resize: "none" }}
                            value={dietaryRequirements}
                            onChange={e => setData(d => ({ ...d, dietaryRequirements: e.target.value }))}
                        />
                        <label htmlFor="floatingTextarea">Enter any dietary requirements we should know about</label>
                    </div>
                </div>
                <div className="col-12 mb-3 col-md-5 pt-md-5  z-index-1">
                    <div className="expense-card">
                        <div className="expense-header d-flex">
                            <div className="text-purple">Your Experience Summary</div>
                            <div className="ms-auto">
                                <button
                                    className={`button small d-md-none light-background ${ariaExpanded ? "collapsed" : ""}`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#expense"
                                    aria-expanded={ariaExpanded}
                                    aria-controls="expense"
                                    onClick={() => setAriaExpanded(!ariaExpanded)}
                                >
                                    {!ariaExpanded ? (
                                        <>
                                            <FontAwesomeIcon fixedWidth className=" " size="1x" icon={["far", "arrow-down"]} />
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon className="" fixedWidth size="1x" icon={["far", "arrow-up"]} />
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className={`collapse ${!isMobileScreen ? "show" : ""}`} id="expense">
                            <div className="expense-body">
                                <div className="mb-3">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your yacht</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("yacht")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    <div className="expense-value">Rental of {vesselName}</div>
                                    <div className="border-sub my-3" />
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your date</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("date")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    <div className="expense-value">{fromTo}</div>
                                    <div className="border-sub my-3" />
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your route</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("route")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    <div className="expense-value">{routeName}</div>
                                    <div className="border-sub my-3" />
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your extras</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("extras")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    {allExtras.map(e => (
                                        <div className="expense-value mb-1" key={e.id}>
                                            {e.extraName}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="expense-footer">
                                <div className="d-flex mb-2">
                                    <div className="expense-label-footer">Boat Rental</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(summary.boatRentalPrice)}</div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div className="expense-label-footer">Fuel</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(summary.fuel)}</div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div className="expense-label-footer">Total Extras</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(summary.totalExtras)}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="expense-label-footer">Sales Tax (21%)</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(salesTax)}</div>
                                </div>
                                <div className="border-total my-3" />
                                <div className="d-flex mb-2">
                                    <div className="expense-total-label">Total Amount so far</div>
                                    <div className="ms-auto expense-total-value">€{formatPrice(totalAmount)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

DietaryRequirements.propTypes = {
    foodExtras: PropTypes.array.isRequired,
    setPage: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    setData: PropTypes.func.isRequired
}

export default DietaryRequirements
