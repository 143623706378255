import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import formatPrice from "../../utils/priceFormatter"

const Yacht = ({ yachtName, capacity, yachtId, selected, guests, setData, lowestPrice, yachtsData }) => {
    const [storedYacht, setStoredYacht] = useState({})
    const multiDay = storedYacht?.type !== "main"

    const isSmall = +guests > +capacity
    const isSelected = yachtId === selected

    const onClickCard = () => {
        if (!isSmall) {
            setData(d => ({
                ...d,
                vesselId: yachtId,
                vesselName: yachtName,
                vesselCapacity: capacity,
                multiDay
            }))
        }
    }

    useEffect(() => {
        if (yachtName) {
            const yacht = yachtsData.find(({ name }) => name === yachtName)
            if (yacht) {
                setStoredYacht(yacht)
            } else {
                setStoredYacht(yachtsData.find(({ name }) => name === "default"))
            }
        }
    }, [yachtName, yachtsData])

    return (
        <div role={isSmall ? "none" : "button"} className="col position-relative" onClick={onClickCard}>
            {storedYacht.type !== "main" && <div className="multiday-badge">Multi-day</div>}
            <div className={`card border-white h-100 ${isSelected ? "yacht-selected-card position-relative" : ""}`}>
                <div className="position-relative">
                    {storedYacht.mainImage && (
                        <GatsbyImage className="card-img-top" image={getImage(storedYacht.mainImage)} alt="Yacht image" />
                    )}
                    {isSmall && (
                        <>
                            <div className="img-overlay-too-small"></div>
                            <div className="yacht-too-small-text py-2">This yacht is too small for your group</div>
                        </>
                    )}
                </div>

                {isSelected && storedYacht?.link && (
                    <a target="_blank" rel="noreferrer" className="text-decoration-none" href={storedYacht.link}>
                        <FontAwesomeIcon className="yacht-info-icon" icon="fas-regular fa-circle-info " size="lg" />
                    </a>
                )}

                <div className={`card-body ${isSelected ? "yacht-selected-card-body" : ""}`}>
                    <div className="row g-0 align-items-center ">
                        <div className="col-12 col-md-5">
                            <h4 className={`mb-md-0 ${isSmall ? "yacht-too-small" : ""}`}>{yachtName}</h4>
                        </div>
                        <div className="col-12 col-md-7 d-flex flex-column ">
                            <div className="row g-0">
                                <div className="col-12 d-flex flex-column align-items-md-end">
                                    <span className={`pre-title text-end mb-1 ${isSmall ? "yacht-too-small" : ""}`}>
                                        up to {capacity} + {storedYacht?.type === "main" ? 1 : 3} people
                                    </span>
                                </div>
                                <div className="col-12 d-flex flex-column align-items-md-end">
                                    <span className={`price ${isSmall ? "yacht-too-small" : ""}`}>
                                        From €{formatPrice(lowestPrice)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Yacht.propTypes = {
    yachtName: PropTypes.string.isRequired,
    capacity: PropTypes.number.isRequired,
    yachtId: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    guests: PropTypes.string.isRequired,
    setData: PropTypes.func.isRequired
}

export default Yacht
