import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"
import AnchorTop from "../../images/anchor-top.svg"
import AnchorBottom from "../../images/anchor-bottom.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import fetchQuery from "../../utils/fetchQuery"
import formatPrice from "../../utils/priceFormatter"
import PropTypes from "prop-types"
import { Popover, OverlayTrigger } from "react-bootstrap"
import moment from "moment"
import { graphql, useStaticQuery } from "gatsby"

const MinusIcon = ({ className, onClick }) => (
    <svg
        role="button"
        onClick={onClick}
        className={className}
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8ZM4 8.5H11V7.5H4V8.5Z"
            fill="#3D4998"
            fillOpacity="0.5"
        />
    </svg>
)

const PlusIcon = ({ className, onClick }) => (
    <svg
        role="button"
        onClick={onClick}
        className={className}
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8ZM7 11.5V8.5H4V7.5H7V4.5H8V7.5H11V8.5H8V11.5H7Z"
            fill="#3D4998"
        />
    </svg>
)

const SelectExtras = ({ data, setPage, setData, isMobileScreen }) => {
    const [ariaExpanded, setAriaExpanded] = useState(!isMobileScreen)
    const [extras, setExtras] = useState([])

    const { extras: extrasState, guests, vesselName, from, to, routeName, summary } = data
    const oneDay = from?.isSame(to) || !to
    const fromTo = !from
        ? ""
        : oneDay
        ? moment(from).format("Do of MMMM YYYY")
        : `${moment(from).format("Do of MMMM YYYY")} - ${moment(to).format("Do of MMM YYYY")}`
    const allExtras = [...extrasState.food, ...extrasState.drinks, ...extrasState.others]
    const allSumm = summary.boatRentalPrice + summary.fuel + summary.totalExtras
    const salesTax = allSumm * 0.21
    const totalAmount = allSumm + salesTax

    const allStoredExtrasQuery = useStaticQuery(graphql`
        query ExtrasData {
            allExtrasJson(filter: { type: { eq: "drinks" } }) {
                nodes {
                    onDayPrice
                    extraName
                }
            }
        }
    `)
    const allStoredExtras = allStoredExtrasQuery.allExtrasJson.nodes

    const getExtras = async () => {
        await fetchQuery({
            method: "GET",
            endPoint: "configuration/Extra/GetExtraList?status=true",
            successCallback: response => {
                setExtras(response.items)
            }
        })
    }

    useEffect(() => {
        getExtras()
    }, [])

    useEffect(() => {
        const allExtras = [...extrasState.food, ...extrasState.drinks]
        const extrasSum = allExtras.reduce((s, e) => s + e.price, 0)
        const othersExtrasSum = extrasState.others.reduce((s, e) => s + e.price * e.count, 0)

        setData(d => ({
            ...d,
            summary: {
                ...d.summary,
                totalExtras: extrasSum + othersExtrasSum
            }
        }))
    }, [extrasState.food, extrasState.drinks, extrasState.others, guests])

    const ExtraItem = ({ extraName, price, id, type, description, maxQuantity, allStoredExtras = [] }) => {
        const active = extrasState[type].some(e => e.id === id)
        const selectedExtra = extrasState[type].find(e => e.id === id)
        const storedExtraOnDayPrice = allStoredExtras.find(e => e.extraName.toLowerCase() === extraName.toLowerCase())?.onDayPrice

        const onSelect = e => {
            if (typeof e.target.className === "string") {
                const totalPrice = type === "others" ? price * (selectedExtra?.count || 1) : price
                if (active) {
                    setData(d => ({
                        ...d,
                        summary: {
                            ...d.summary,
                            totalExtras: d.summary.totalExtras - totalPrice
                        },
                        extras: {
                            ...d.extras,
                            [`${type}`]: d.extras[type].filter(e => e.id !== id)
                        }
                    }))
                } else {
                    setData(d => ({
                        ...d,
                        summary: {
                            ...d.summary,
                            totalExtras: d.summary.totalExtras + totalPrice
                        },
                        extras: {
                            ...d.extras,
                            [`${type}`]: [
                                ...d.extras[type],
                                {
                                    id,
                                    extraName,
                                    price,
                                    count: 1,
                                    type
                                }
                            ]
                        }
                    }))
                }
            }
        }

        const ExtrasPopover = () => (
            <OverlayTrigger
                trigger="hover"
                rootClose
                placement="right"
                overlay={
                    <Popover id={id}>
                        <Popover.Body>
                            {description.split("\n").map(i => (
                                <p key={i} className="mb-0">
                                    {i}
                                </p>
                            ))}
                        </Popover.Body>
                    </Popover>
                }
            >
                <span role="button" className="ms-2">
                    <FontAwesomeIcon className="ms-1 text-lightblue" icon="fa-solid fa-circle-info" />
                </span>
            </OverlayTrigger>
        )

        const totalItemPrice = type === "others" ? price * (selectedExtra?.count || 1) : price

        const onPlusItem = () => {
            const index = data.extras.others.findIndex(e => e.id === id)

            if (!maxQuantity) {
                setData(d => {
                    const copyExtras = [...d.extras.others]
                    copyExtras[index] = {
                        ...copyExtras[index],
                        count: d.extras.others[index].count + 1
                    }
                    return {
                        ...d,
                        summary: {
                            ...d.summary,
                            totalExtras: d.summary.totalExtras + price
                        },
                        extras: {
                            ...d.extras,
                            [`${type}`]: copyExtras
                        }
                    }
                })
            } else {
                setData(d => {
                    const copyExtras = [...d.extras.others]
                    copyExtras[index] = {
                        ...copyExtras[index],
                        count: d.extras.others[index].count + 1
                    }
                    return d.extras.others[index].count + 1 <= maxQuantity
                        ? {
                              ...d,
                              summary: {
                                  ...d.summary,
                                  totalExtras: d.summary.totalExtras + price
                              },
                              extras: {
                                  ...d.extras,
                                  [`${type}`]: copyExtras
                              }
                          }
                        : d
                })
            }
        }

        const onMinusItem = () => {
            const index = data.extras.others.findIndex(e => e.id === id)
            setData(d => {
                const copyExtras = [...d.extras.others]
                copyExtras[index] = {
                    ...copyExtras[index],
                    count: d.extras.others[index].count - 1
                }
                return d.extras.others[index].count - 1 >= 1
                    ? {
                          ...d,
                          summary: {
                              ...d.summary,
                              totalExtras: d.summary.totalExtras + price
                          },
                          extras: {
                              ...d.extras,
                              [`${type}`]: copyExtras
                          }
                      }
                    : d
            })
        }

        return (
            <>
                {type !== "drinks" ? (
                    <div className="col">
                        <div
                            className={`route-card ${active ? "selected" : ""} d-flex align-items-start`}
                            role="button"
                            onClick={onSelect}
                        >
                            <div className="d-flex flex-column">
                                <div role="button" onClick={onSelect} className="route-name mb-3 mb-md-0">
                                    {extraName}

                                    {description.length > 0 && <ExtrasPopover />}
                                </div>
                                {active && type === "others" && (
                                    <div className="mt-md-3 mb-3 d-flex align-items-end">
                                        <MinusIcon
                                            className={`plus-minus${selectedExtra.count - 1 >= 1 ? " active" : ""}`}
                                            onClick={onMinusItem}
                                        />
                                        <p className="quantity px-2 d-block mb-0">{selectedExtra.count}</p>
                                        <PlusIcon
                                            className={`plus-minus${
                                                !maxQuantity || selectedExtra.count + 1 <= maxQuantity ? " active" : ""
                                            }`}
                                            onClick={onPlusItem}
                                        />
                                    </div>
                                )}
                                <div className="route-price mb-2 d-md-none">+€{formatPrice(totalItemPrice)}</div>
                            </div>
                            <div className="route-price d-none d-md-flex justify-content-end">+€{formatPrice(totalItemPrice)}</div>
                        </div>
                    </div>
                ) : (
                    <div className="col-12" role="button" onClick={onSelect}>
                        <div className={`extra-card ${active ? "selected" : ""}`}>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between">
                                    <div>
                                        <div className="route-name mb-3 mb-md-0">{extraName}</div>
                                    </div>
                                    <div className="route-price text-end">+€{formatPrice(totalItemPrice)}</div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12 extra-card__text">
                                    {description.split("\n").map(i => (
                                        <p key={i} className="mb-0 ">
                                            {i}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            {storedExtraOnDayPrice && (
                                <div className="row mt-2">
                                    <p className="extra-card__notice-text">
                                        PLEASE NOTE: This package can be ordered on the day for the cost of{" "}
                                        <b>€{formatPrice(storedExtraOnDayPrice)}</b>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </>
        )
    }

    const foodExtras = extras.filter(e => e.category === "Food")
    const drinksExtras = extras.filter(e => e.category === "Drinks")
    const othersExtras = extras.filter(e => e.category === "Others")

    return (
        <div className="container-xxl flex-grow-1 top-gradient-container">
            <div className="row h-100 position-relative overflow-hidden">
                <img src={AnchorTop} className="overlay-anchor-top-booking d-none d-md-block" alt="Anchor vector" />
                <StaticImage className="d-none d-md-block bg-your-details" src="../../images/water5.png" alt="Water Background" />
                <img src={AnchorBottom} className="overlay-anchor-bottom-booking d-none d-md-block" alt="Anchor bottom vector" />
                <div className="col-12 pt-5 col-md-7 ps-inside z-index-1">
                    <span className="breadcrumbs mb-3">
                        Book your experience &gt; Details &gt; Yacht &gt; Date &gt; Route &gt; Extras
                    </span>
                    <h2>Select Extras</h2>
                    <p className="mb-md-4">
                        When we say your own personalized experience, we mean it! Select your food, drink and other extras to make your
                        day special, we cater to all groups and all sizes.
                    </p>
                    {foodExtras.length ? (
                        <>
                            <h3>Food</h3>
                            <div className="row g-3 row-cols-1 mb-3">
                                {foodExtras.map(e => (
                                    <ExtraItem
                                        key={e.id}
                                        extraName={e.extraName}
                                        price={e.price}
                                        description={e.description}
                                        id={e.id}
                                        type="food"
                                    />
                                ))}
                            </div>
                        </>
                    ) : null}
                    {drinksExtras.length ? (
                        <>
                            <h3>Drinks</h3>
                            <div className="row g-3 row-cols-1 mb-3">
                                {drinksExtras.map(e => (
                                    <ExtraItem
                                        key={e.id}
                                        extraName={e.extraName}
                                        price={e.price}
                                        description={e.description}
                                        id={e.id}
                                        type="drinks"
                                        allStoredExtras={allStoredExtras}
                                    />
                                ))}
                            </div>
                        </>
                    ) : null}
                    {othersExtras.length ? (
                        <>
                            <h3>Others</h3>
                            <div className="row g-3 row-cols-1 mb-3">
                                {othersExtras
                                    .sort((a, b) => a.extraName.localeCompare(b.extraName))
                                    .map(e => (
                                        <ExtraItem
                                            key={e.id}
                                            extraName={e.extraName}
                                            price={e.price}
                                            description={e.description}
                                            id={e.id}
                                            type="others"
                                            maxQuantity={e.maxQuantity}
                                        />
                                    ))}
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="col-12 mb-3 col-md-5 pt-md-5  z-index-1">
                    <div className="expense-card">
                        <div className="expense-header d-flex">
                            <div className="text-purple">Your Experience Summary</div>
                            <div className="ms-auto">
                                <button
                                    className={`button small d-md-none light-background ${ariaExpanded ? "collapsed" : ""}`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#expense"
                                    aria-expanded={ariaExpanded}
                                    aria-controls="expense"
                                    onClick={() => setAriaExpanded(!ariaExpanded)}
                                >
                                    {!ariaExpanded ? (
                                        <>
                                            <FontAwesomeIcon fixedWidth className=" " size="1x" icon={["far", "arrow-down"]} />
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon className="" fixedWidth size="1x" icon={["far", "arrow-up"]} />
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className={`collapse ${!isMobileScreen ? "show" : ""}`} id="expense">
                            <div className="expense-body">
                                <div className="mb-3">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your yacht</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("yacht")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    <div className="expense-value">Rental fo {vesselName}</div>
                                    <div className="border-sub my-3" />
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your date</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("date")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    <div className="expense-value">{fromTo}</div>
                                    <div className="border-sub my-3" />
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your route</div>
                                        <div
                                            className="ms-auto expense-edit"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPage("route")}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                    <div className="expense-value">{routeName}</div>
                                    <div className="border-sub my-3" />
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label">Your extras</div>
                                    </div>
                                    {allExtras.map(e => (
                                        <div
                                            key={e.id}
                                            className="d-flex justify-content-between align-items-center expense-value mb-1"
                                        >
                                            <div>{e.extraName}</div>
                                            {e.count !== 1 && <div>x{e.count}</div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="expense-footer">
                                <div className="d-flex mb-2">
                                    <div className="expense-label-footer">Boat Rental</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(summary.boatRentalPrice)}</div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div className="expense-label-footer">Fuel</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(summary.fuel)}</div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div className="expense-label-footer">Total Extras</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(summary.totalExtras)}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="expense-label-footer">Sales Tax (21%)</div>
                                    <div className="ms-auto expense-value-footer">€{formatPrice(salesTax)}</div>
                                </div>
                                <div className="border-total my-3" />
                                <div className="d-flex mb-2">
                                    <div className="expense-total-label">Total Amount so far</div>
                                    <div className="ms-auto expense-total-value">€{formatPrice(totalAmount)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SelectExtras.propTypes = {
    data: PropTypes.object.isRequired,
    setPage: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired
}

export default SelectExtras
