import React, { useEffect } from "react"
import { useStripe, PaymentElement, useElements } from "@stripe/react-stripe-js"

export let stripeContextValue = {}

const CheckoutForm = ({ clientSecret, errorMessage, setErrorMessage, setLoadingStripe }) => {
    const stripe = useStripe()
    const elements = useElements()

    if (stripe && elements) {
        stripeContextValue = {
            stripe,
            elements
        }
    }

    useEffect(() => {
        if (!stripe) {
            return
        }

        if (!clientSecret) {
            return
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setErrorMessage("Payment succeeded!")
                    break
                case "processing":
                    setErrorMessage("Your payment is processing.")
                    break
                case "payment_failed":
                    setErrorMessage("Your payment was not successful, please try again.")
                    break
            }
        })
    }, [stripe, clientSecret])

    return (
        <form id="payment-form">
            <PaymentElement id="payment-element" onReady={() => setLoadingStripe(false)} />
            {errorMessage && <div id="payment-message">{errorMessage}</div>}
        </form>
    )
}

export default CheckoutForm
