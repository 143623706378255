import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Obfuscate from "react-obfuscate"

const Socials = props => (
    <div className="">
        <a
            href="https://www.instagram.com/maliblueyachts/"
            target="_blank"
            rel="noreferrer"
            aria-label="MBY Instagram"
            alt="MBY Instagram"
        >
            <FontAwesomeIcon className={props.me} size="2x" icon="fa-brands fa-instagram" />
        </a>
        <a
            href="https://www.facebook.com/Maliblue-Yachts-102827445682541"
            target="_blank"
            rel="noreferrer"
            aria-label="MBY Facebook"
            alt="MBY Facebook"
        >
            <FontAwesomeIcon size="2x" className={props.me} icon={["fab", "facebook"]} />
        </a>
        <a href="https://wa.me/34673071322" target="_blank" rel="noreferrer" aria-label="MBY Whatsapp" alt="MBY Whatsapp">
            <FontAwesomeIcon size="2x" className={props.me} icon={["fa-brands", "fa-whatsapp"]} />
        </a>
        {props.mail ? (
            <Obfuscate email="chartering@malibueyachts.com" aria-label="email">
                <FontAwesomeIcon size="2x" icon={["fas", "envelope"]} />
            </Obfuscate>
        ) : (
            ""
        )}
    </div>
)

export default Socials
