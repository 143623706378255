import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import AnchorTop from "../../images/anchor-top.svg"
import AnchorBottom from "../../images/anchor-bottom.svg"
import PhoneInput from "react-phone-number-input/input"
import PropTypes from "prop-types"
import Cleave from "cleave.js/react"

const countPeople = ["Select", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]

const YourDetails = ({ data, setData, errors }) => (
    <div className="container-xxl flex-grow-1 top-gradient-container">
        <div className="row h-100 position-relative overflow-hidden">
            <img src={AnchorTop} className="overlay-anchor-top-booking d-none d-md-block" alt="Anchor vector" />
            <StaticImage className="d-none d-md-block bg-your-details" src="../../images/water5.png" alt="Water Background" />
            <img src={AnchorBottom} className="overlay-anchor-bottom-booking d-none d-md-block" alt="Anchor bottom background" />
            <div className="col-12 pt-5 col-md-8 ps-inside">
                <span className="breadcrumbs mb-3">Book your experience &gt; Details</span>
                <h2>Your Details</h2>
                <p className="mb-md-4">
                    Please input your details below to proceed with your booking for your personalized yacht experience with Maliblue
                    Yachts.
                </p>
                <div className="row gy-3 g-md-3 mb-3">
                    <div className="col-12 col-md-6">
                        <div className="form-floating">
                            <input
                                value={data.firstName}
                                onChange={e => setData({ ...data, firstName: e.target.value })}
                                type="text"
                                className={`form-control ${errors.firstName ? "error" : ""}`}
                                id="firstName"
                                placeholder="Your first name"
                            />
                            <label htmlFor="firstName">Your first name</label>
                        </div>
                        {errors.firstName && <div className="error-text">{errors.firstName}</div>}
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-floating">
                            <input
                                value={data.lastName}
                                onChange={e => setData({ ...data, lastName: e.target.value })}
                                type="text"
                                className={`form-control ${errors.lastName ? "error" : ""}`}
                                id="lastName"
                                placeholder="Your last name"
                            />
                            <label htmlFor="lastName">Your last name</label>
                        </div>
                        {errors.lastName && <div className="error-text">{errors.lastName}</div>}
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-floating">
                            <input
                                value={data.emailAddress}
                                onChange={e => setData({ ...data, emailAddress: e.target.value })}
                                type="text"
                                className={`form-control ${errors.emailAddress ? "error" : ""}`}
                                id="email"
                                placeholder="Your email address"
                            />
                            <label htmlFor="email">Your email address</label>
                        </div>
                        {errors.emailAddress && <div className="error-text">{errors.emailAddress}</div>}
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-floating">
                            <PhoneInput
                                value={data.phoneNumber || ""}
                                onChange={e => setData({ ...data, phoneNumber: e })}
                                type="text"
                                className={`form-control ${errors.phoneNumber ? "error" : ""}`}
                                id="phoneNumber"
                                placeholder="Your phone number"
                            />
                            <label htmlFor="phoneNumber">Your phone number</label>
                        </div>
                        {errors.phoneNumber && <div className="error-text">{errors.phoneNumber}</div>}
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-floating">
                            <Cleave
                                options={{ date: true, datePattern: ["d", "m", "y"] }}
                                value={data.birthDate}
                                onChange={e => setData(d => ({ ...d, birthDate: e.target.value }))}
                                type="text"
                                className={"form-control" + (errors.birthDate ? " error" : "")}
                                id="birthDate_1"
                                placeholder="Date of Birth (DD/MM/YY)"
                            />
                            <label htmlFor="birthDate_1">Date of Birth (DD/MM/YY)</label>
                        </div>
                        {errors.birthDate && <div className="error-text">{errors.birthDate}</div>}
                    </div>

                    <div className="col-12 col-md-12">
                        <div className="form-check">
                            <input
                                checked={data.subscribe}
                                onChange={e => setData({ ...data, subscribe: e.target.checked })}
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                            />
                            <label className="form-check-label  disclaimer" htmlFor="flexCheckChecked">
                                I’m happy to recieve news and offer emails and other promotional materials about Maliblue Yachts. For
                                more information please read out{" "}
                                <Link to="/privacy">
                                    <b>privacy policy</b>
                                </Link>
                                . You’re able to unsubscribe at any time.
                            </label>
                        </div>
                    </div>
                </div>
                <h2>The Guests</h2>
                <div className="row gy-3 g-md-3 mb-5">
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="form-floating">
                            <select
                                onChange={e => setData({ ...data, guests: e.target.value })}
                                value={data.guests}
                                placeholder="Select 1"
                                className={`form-select ${errors.guests ? "error" : ""}`}
                                id="numberOfGuests"
                                aria-label="Number of guests"
                            >
                                {countPeople.map(n => (
                                    <option key={n} value={n}>
                                        {n}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="numberOfGuests">Number of guests</label>
                        </div>
                        {errors.guests && <div className="error-text">{errors.guests}</div>}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-floating">
                            <select
                                onChange={e => setData({ ...data, adults: e.target.value })}
                                value={data.adults}
                                className={`form-select ${errors.adults ? "error" : ""}`}
                                id="numberOfAdults"
                                aria-label="Number of adults"
                            >
                                {countPeople.map(n => (
                                    <option key={n} value={n}>
                                        {n}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="numberOfAdults">Adults (Over 16s)</label>
                        </div>
                        {errors.adults && <div className="error-text">{errors.adults}</div>}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-floating">
                            <select
                                onChange={e => setData({ ...data, children: e.target.value })}
                                value={data.children}
                                className={`form-select ${errors.children ? "error" : ""}`}
                                id="numberOfChildren"
                                aria-label="Number of children"
                            >
                                {countPeople.map(n => (
                                    <option key={n} value={n}>
                                        {n}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="numberOfChildren">Children (0-15)</label>
                        </div>
                        {errors.children && <div className="error-text">{errors.children}</div>}
                    </div>
                </div>
            </div>
        </div>
    </div>
)

YourDetails.propTypes = {
    data: PropTypes.object.isRequired,
    setData: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
}

export default YourDetails
