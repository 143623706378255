import * as React from "react"
import { isValidPhoneNumber } from "react-phone-number-input"
import ChooseYourYacht from "../components/booking/chooseYourYacht"
import DietaryRequirements from "../components/booking/dietaryRequirements"
import SelectDate from "../components/booking/selectDate"
import SelectExtras from "../components/booking/selectExtras"
import SelectRoute from "../components/booking/selectRoute"
import YourDetails from "../components/booking/yourDetails"
import YourPaymentDetails from "../components/booking/yourPaymentDetails"
import { FooterBooking } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import fetchQuery from "../utils/fetchQuery"
import * as moment from "moment"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useWindowWidth } from "@react-hook/window-size"
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import { decompressFromEncodedURIComponent } from "lz-string"
import { isProdLocation, sendEventAnalytics } from "../utils/helpers"
import { STRIPE_PB_KEYS } from "../utils/constants"
import { bookingSteps } from "../utils/constants"
import { navigate } from "gatsby"
import { stripeContextValue } from "../components/booking/checkoutForm"

const pbk = isProdLocation() ? STRIPE_PB_KEYS.PROD : STRIPE_PB_KEYS.DEV

const stripePromise = loadStripe(pbk)

export const StripeContext = React.createContext({
    stripe: undefined,
    elements: undefined
})

const Book = ({ location }) => {
    const screenWidth = useWindowWidth()
    const isMobileScreen = screenWidth <= 768
    const [errors, setErrors] = React.useState({})
    const [failReserv, setFailReserv] = React.useState(null)
    const [clientInfo, setClientInfo] = React.useState({})
    const [isLoadingPayment, setIsLoadingPayment] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [errorBooking, setErrorBooking] = React.useState(null)
    const [dateError, setDateError] = React.useState(null)
    const [stripeError, setStripeError] = React.useState(null)
    const [refreshing, setRefreshing] = React.useState(false)
    const [query, setQuery] = useQueryParams({
        d: StringParam,
        page: withDefault(StringParam, "details")
    })

    const { page } = query
    const [data, setData] = React.useState({
        id: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        birthDate: "",
        subscribe: false,
        guests: "Select",
        adults: "Select",
        children: "Select",
        vesselId: location.state?.vesselId ?? "",
        vesselName: location.state?.vesselName ?? "",
        vesselCapacity: location.state?.vesselCapacity ?? "",
        from: location.state?.from ? moment(location.state?.from) : undefined,
        to: location.state?.to ? moment(location.state?.to) : undefined,
        summary: {
            boatRentalPrice: 0,
            fuel: 0,
            salesTax: 0,
            totalExtras: 0,
            totalAmount: 0
        },
        routeId: "",
        extras: {
            food: [],
            drinks: [],
            others: []
        },
        dietaryRequirements: "",
        billingFirstName: "",
        billingLastName: "",
        country: "Open this select menu",
        city: "",
        postcode: "",
        address: "",
        multiDay: location.state?.multiDay ?? false
    })

    const { summary } = data

    React.useEffect(() => {
        if (page === bookingSteps[6].urlName) updateBooking()
    }, [page])

    React.useEffect(() => {
        setQuery({ page: bookingSteps[0].urlName })
    }, [])

    React.useEffect(() => {
        if (query.d) {
            const decompressed = decompressFromEncodedURIComponent(query.d)
            if (decompressed) {
                const json = JSON.parse(decompressed)
                setData({
                    ...json,
                    from: moment(json.from),
                    to: moment(json.to),
                    fromLink: true
                })
                setQuery({ page: bookingSteps[6].urlName })
                setQuery({ d: undefined })
            }
        }
    }, [query.d])

    React.useEffect(() => {
        setFailReserv(null)
    }, [page])

    React.useEffect(() => {
        const sum = summary.boatRentalPrice + summary.fuel + summary.totalExtras
        setData(d => ({
            ...d,
            summary: {
                ...d.summary,
                salesTax: sum * 0.21,
                totalAmount: sum + sum * 0.21
            }
        }))
    }, [summary.boatRentalPrice, summary.fuel, summary.totalExtras])

    const detailsValidation = () => {
        const newErrors = {}

        if (!data.firstName?.length) {
            newErrors.firstName = "This is not a valid First Name"
        }
        if (!data.lastName?.length) {
            newErrors.lastName = "This is not a valid Last Name"
        }

        let re = /\S+@\S+\.\S+/
        if (!re.test(data.emailAddress)) {
            newErrors.emailAddress = "This is not a valid email address"
        }

        if (!data.phoneNumber?.length) {
            newErrors.phoneNumber = "This is not a valid phone number"
        } else {
            if (data.phoneNumber && !isValidPhoneNumber(data.phoneNumber)) {
                newErrors.phoneNumber = "This is not a valid phone number"
            }
        }

        if (!data.birthDate.length || (data.birthDate && data.birthDate.length !== 8)) {
            newErrors.birthDate = "Example: 27/01/90 for 27 January 1990"
        }

        if (data.guests === "Select") {
            newErrors.guests = "This is not a valid number of guests"
        }

        if (+data.guests > 0 && data.adults === "Select" && data.children === "Select") {
            newErrors.adults = "This is not a valid number of adults"
            newErrors.children = "This is not a valid number of children"
        } else {
            const adults = data.adults === "Select" ? 0 : +data.adults
            const children = data.children === "Select" ? 0 : +data.children

            const sumGuests = adults + children
            if (+data.guests !== sumGuests) {
                newErrors.adults = "This is not a valid number of adults"
                newErrors.children = "This is not a valid number of children"
            }
        }
        setErrors(newErrors)

        return newErrors
    }

    const billingValidation = () => {
        const newErrors = {}

        if (!data.billingFirstName.length) {
            newErrors.billingFirstName = "This is not a valid First Name"
        }
        if (!data.billingLastName.length) {
            newErrors.billingLastName = "This is not a valid Last Name"
        }
        if (data.country === "Open this select menu") {
            newErrors.country = "This is not a valid Country"
        }
        if (!data.address.length) {
            newErrors.address = "This is not a valid Address"
        }
        if (!data.city.length) {
            newErrors.city = "This is not a valid City"
        }
        if (!data.postcode.length) {
            newErrors.postcode = "This is not a valid Postcode"
        }

        return newErrors
    }

    const yachtsValidation = () => {
        const newErrors = {}

        if (!data.vesselId.length) {
            newErrors.vesselId = "Empty"
        }
        if (data.vesselCapacity < data.guests) {
            newErrors.capacity = "This yacht is too small"
        }

        setErrors(newErrors)
        return newErrors
    }

    const onReserveBooking = async () => {
        let error = null
        const { vesselId, from, to, guests, adults, children } = data

        if (!from) {
            setFailReserv("Need to select date")
            return
        }

        setDateError(null)

        let respo = await fetchQuery({
            method: "POST",
            endPoint: "Booking/ReserveBooking",
            body: {
                vesselId,
                from: moment(from).format("YYYY-MM-DD"),
                to: to ? moment(to).add(1, "days").format("YYYY-MM-DD") : moment(from).add(1, "days").format("YYYY-MM-DD"),
                guests: +guests,
                adults: adults === "Select" ? 0 : +adults,
                children: children === "Select" ? 0 : +children
            },
            successCallback: async response => {
                const bookId = await response.text()
                setData(d => ({ ...d, id: bookId }))
                setFailReserv(null)
            },
            failCallback: async (response = undefined) => {
                if (response) {
                    error = await response?.text()
                    setFailReserv(error)
                }
            }
        })
        return respo.ok
    }

    const nextPage = async () => {
        let errors = {}
        let reserveStatus = true
        if (page === bookingSteps[0].urlName) {
            errors = detailsValidation()
        } else if (page === bookingSteps[1].urlName) {
            errors = yachtsValidation()
        } else if (page === bookingSteps[2].urlName) {
            setFailReserv(null)
            if (data.guests !== "Select") {
                if (!data.id) {
                    reserveStatus = await onReserveBooking().then(status => status)
                } else {
                    reserveStatus = await updateBooking(false).then(status => status)
                }
            } else {
                errors.guests = "This is not a valid number of guests"
                setQuery({ page: bookingSteps[0].urlName })
            }

            if (!data.to) setData(d => ({ ...d, to: data.from }))
        } else if (page === bookingSteps[3].urlName) {
            if (!data.routeId) errors.route = "Route is missing."
        } else if (page === bookingSteps[4].urlName) {
            if (!data.extras.food.length) {
                setQuery({ page: bookingSteps[6].urlName })
                return
            }
        }

        if (!Object.keys(errors).length && reserveStatus) {
            switch (page) {
                case bookingSteps[0].urlName:
                    {
                        const adults = data.adults !== "Select" ? data.adults : 0
                        const children = data.children !== "Select" ? data.children : 0
                        const { birthDate } = data
                        sendEventAnalytics("event", "booking_details", { completed: true, birthDate, adults, children })
                    }
                    break
                case bookingSteps[1].urlName:
                    sendEventAnalytics("event", "booking_yacht", { completed: true, yachtName: data.vesselName })
                    break
                case bookingSteps[2].urlName:
                    {
                        const from = data?.from ? moment(data.from).format("DD-MM-YYYY") : undefined
                        const to = data?.to ? moment(data.to).format("DD-MM-YYYY") : undefined
                        sendEventAnalytics("event", "booking_date", { completed: true, from, to })
                    }
                    break
                case bookingSteps[3].urlName:
                    sendEventAnalytics("event", "booking_route", { completed: true, routeName: data.routeName })
                    break
                case bookingSteps[4].urlName:
                    sendEventAnalytics("event", "booking_extras", {
                        completed: true,
                        ...[...data.extras.food, ...data.extras.drinks, ...data.extras.others].reduce(
                            (obj, val) => ({ ...obj, [`${val.extraName}`]: val.count }),
                            {}
                        )
                    })
                    break
                default:
                    break
            }

            const index = bookingSteps.findIndex(e => e.urlName === page)
            setQuery({ page: bookingSteps[index + 1].urlName })
        }
    }

    const prevPage = () => {
        const index = bookingSteps.findIndex(e => e.urlName === page)
        // if payment page
        if (page === bookingSteps[6].urlName) {
            //if food extras is empty
            if (!data.extras.food.length) {
                setQuery({ page: bookingSteps[index - 2].urlName })
            } else {
                setQuery({ page: bookingSteps[index - 1].urlName })
            }
        } else {
            setQuery({ page: bookingSteps[index - 1].urlName })
        }
    }

    const onPay = async (stripe, elements) => {
        setIsLoadingPayment(true)
        const billingErrors = billingValidation()

        setErrors(billingErrors)

        if (!stripe || !elements) {
            setIsLoadingPayment(false)
            return
        }

        if (!Object.keys(billingErrors).length) {
            let updateResponse = undefined

            try {
                updateResponse = await fetchQuery({
                    method: "POST",
                    endPoint: "Booking/UpdateBooking",
                    body: {
                        ...data,
                        birthDate: moment(data.birthDate, "DD/MM/YY").format("YYYY-MM-DD"),
                        extras: [...data.extras.food, ...data.extras.drinks, ...data.extras.others].map(e => ({
                            extraId: e.id,
                            count: e.type !== "others" ? undefined : +e.count
                        })),
                        from: moment(data.from).format("YYYY-MM-DD"),
                        to: data.to
                            ? moment(data.to).add(1, "days").format("YYYY-MM-DD")
                            : moment(data.from).add(1, "days").format("YYYY-MM-DD"),
                        guests: +data.guests,
                        adults: data.adults === "Select" ? 0 : +data.adults,
                        children: data.children === "Select" ? 0 : +data.children,
                        vesselName: undefined,
                        routeName: undefined,
                        summary: undefined,
                        fromLink: undefined,
                        multiDay: undefined
                    },
                    toJSON: false
                })

                if (updateResponse.status === 200) {
                    const allSumm = data.summary.boatRentalPrice + data.summary.fuel + data.summary.totalExtras
                    const salesTax = allSumm * 0.21
                    const totalAmount = allSumm + salesTax

                    const { error, paymentIntent } = await stripe.confirmPayment({
                        elements,
                        confirmParams: {
                            return_url: window.location.origin + "/thank-you/"
                        },
                        redirect: "if_required"
                    })

                    if (paymentIntent?.status === "succeeded" && !error) {
                        sendEventAnalytics("event", "booked", { totalAmount })
                        navigate("/thank-you")
                    }

                    if (error && (error.type === "card_error" || error.type === "validation_error")) {
                        setErrorMessage(error.message)
                        setIsLoadingPayment(false)
                    } else if (error) {
                        setErrorMessage("An unexpected error occured.")
                        setIsLoadingPayment(false)
                    }
                } else {
                    setErrorMessage("An unexpected error occured.")
                    setIsLoadingPayment(false)
                }
            } catch (error) {
                setErrorMessage("An unexpected error occured.")
                setIsLoadingPayment(false)
            }
        } else {
            setIsLoadingPayment(false)
        }
    }

    const updateBooking = async (parse = true) => {
        let error = null
        let resp = await fetchQuery({
            method: "POST",
            endPoint: "Booking/UpdateBooking",
            body: {
                ...data,
                birthDate: moment(data.birthDate, "DD/MM/YY").format("YYYY-MM-DD"),
                extras: [...data.extras.food, ...data.extras.drinks, ...data.extras.others].map(e => ({
                    extraId: e.id,
                    count: e.type !== "others" ? undefined : +e.count
                })),
                from: moment(data.from).format("YYYY-MM-DD"),
                to: data.to
                    ? moment(data.to).add(1, "days").format("YYYY-MM-DD")
                    : moment(data.from).add(1, "days").format("YYYY-MM-DD"),
                guests: +data.guests,
                adults: data.adults === "Select" ? 0 : +data.adults,
                children: data.children === "Select" ? 0 : +data.children,
                country: data.country === "0" ? "" : data.country,
                vesselName: undefined,
                routeName: undefined,
                summary: undefined,
                cardExpiry: undefined,
                cardNumber: undefined,
                cvc: undefined,
                fromLink: undefined,
                multiDay: undefined
            },
            toJSON: parse,
            successCallback: response => {
                setClientInfo({})
                setClientInfo(response)
                setFailReserv(null)
            },
            failCallback: async (response = undefined) => {
                setErrorMessage("An unexpected error occured.")
                if (response) {
                    error = await response?.text()
                    if (error === "Connection problem with the payment provider") {
                        setStripeError(true)
                        setFailReserv(error)
                    } else {
                        setFailReserv(error)

                        if (error.includes("Booking is paid")) {
                            setErrorBooking("Booking is Paid")
                        }

                        if (error.includes("id is incorrect") && data?.fromLink) {
                            setErrorBooking("Booking is Expired. Please get new Payment link.")
                        }

                        if (error.includes("id is incorrect") && !data.fromLink) {
                            setDateError("Booking is expired. Please check your days.")
                            setData(d => ({
                                ...d,
                                from: undefined,
                                to: undefined,
                                id: ""
                            }))
                            setQuery({ page: bookingSteps[2].urlName })
                        }
                    }
                }
            }
        })
        setRefreshing(false)
        return resp.ok
    }

    const stripeRefresh = async () => {
        setStripeError(false)
        setRefreshing(true)
        await updateBooking()
    }

    const appearance = {
        theme: "stripe"
    }
    const options = React.useMemo(
        () => ({
            clientSecret: clientInfo.clientSecret,
            appearance
        }),
        [clientInfo?.clientSecret]
    )

    const setPage = pageParam => setQuery({ page: pageParam })

    return (
        <Layout fullHeight>
            <Seo title="Your Details" />
            <div className="d-flex flex-column h-100 book">
                <NavBar whiteNav />
                {!errorBooking && (
                    <>
                        {page === "details" && (
                            <YourDetails query={query} setQuery={setQuery} errors={errors} data={data} setData={setData} />
                        )}
                        {page === "yacht" && <ChooseYourYacht data={data} setData={setData} />}
                        {page === "date" && (
                            <SelectDate
                                dateError={dateError}
                                data={data}
                                setPage={setPage}
                                setData={setData}
                                failReserv={failReserv}
                                isMobileScreen={isMobileScreen}
                            />
                        )}
                        {page === "route" && (
                            <SelectRoute data={data} setPage={setPage} setData={setData} isMobileScreen={isMobileScreen} />
                        )}
                        {page === "extras" && (
                            <SelectExtras data={data} setPage={setPage} setData={setData} isMobileScreen={isMobileScreen} />
                        )}
                        {page === "dietary" && (
                            <DietaryRequirements
                                data={data}
                                setPage={setPage}
                                setData={setData}
                                foodExtras={data.extras.food}
                                isMobileScreen={isMobileScreen}
                            />
                        )}

                        {page === "payment" && (
                            <YourPaymentDetails
                                clientInfo={clientInfo}
                                errors={errors}
                                data={data}
                                setData={setData}
                                setPage={setPage}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                                isMobileScreen={isMobileScreen}
                                stripeError={stripeError}
                                options={options}
                                stripePromise={stripePromise}
                                stripeRefresh={stripeRefresh}
                                refreshing={refreshing}
                            />
                        )}
                        <StripeContext.Provider value={stripeContextValue}>
                            <FooterBooking
                                page={page}
                                steps={bookingSteps}
                                foodExtras={data.extras.food}
                                nextPage={nextPage}
                                prevPage={prevPage}
                                onPay={onPay}
                                clientSecret={clientInfo?.clientSecret}
                                isLoadingPayment={isLoadingPayment}
                            />
                        </StripeContext.Provider>
                    </>
                )}
                {errorBooking && (
                    <div style={{ flex: 1 }} className="d-flex justify-content-center align-items-center">
                        {errorBooking}
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default Book
