import { useWindowWidth } from "@react-hook/window-size"
import React, { useEffect, useMemo, useState } from "react"
import { DayPickerRangeController } from "react-dates"
import { START_DATE } from "react-dates/constants"
import moment from "moment"
import fetchQuery from "../utils/fetchQuery"
import ArrowIcon from "./planning/arrowIcon"

const BookCalendar = ({
    yachtPage,
    from,
    to,
    vesselId,
    exceptBookingId,
    setData,
    setDaysData,
    daysData,
    setError = () => {},
    multiSelect
}) => {
    const [currentMonth, setCurrentMonth] = useState(from ?? moment())
    const [focusedInput, setFocusedInput] = useState("startDate")
    const screenWidth = useWindowWidth()

    const today = moment().subtract(1, "days")

    const getBookingData = async () => {
        const maxDaysInMonth = currentMonth.daysInMonth()
        const from = currentMonth.format("YYYY-MM-01")
        const to = currentMonth.format(`YYYY-MM-${maxDaysInMonth}`)
        const endPoint = `configuration/Planning/GetAvailabilities?vesselId=${vesselId}&from=${from}&to=${to}${
            exceptBookingId ? "&exceptBookingId=" + exceptBookingId : ""
        }`

        const monthAlreadyExist = daysData.some(d => moment(d.date).format("YYYY-MM-DD") === from)

        if (!monthAlreadyExist) {
            await fetchQuery({
                method: "GET",
                endPoint,
                successCallback: response => {
                    setDaysData([...daysData, ...response])
                },
                failCallback: () => {
                    setError("Something wet wrong. Please reload the page.")
                }
            })
        }
    }

    const isDayBlocked = React.useCallback(
        day => {
            const dayInfo = daysData.find(d => d.date === day.format("YYYY-MM-DDT00:00:00"))
            return !dayInfo?.price || dayInfo.booked || dayInfo?.blocked || day.isBefore(today)
        },
        [daysData]
    )

    const onDatesChangeSingle = ({ startDate, endDate }) => {
        if (focusedInput === START_DATE) {
            endDate = startDate
            setData(d => ({ ...d, from: startDate, to: endDate }))
        }
        setFocusedInput(START_DATE)
    }

    const onDatesChangeMulti = ({ startDate, endDate }) => setData(d => ({ ...d, from: startDate, to: endDate }))

    const config = useMemo(
        () => ({
            hideKeyboardShortcutsPanel: true,
            daySize: screenWidth < 490 ? 38 : 57,
            startDate: from,
            endDate: to,
            onFocusChange: multiSelect ? focusedInput => setFocusedInput(focusedInput || "startDate") : undefined,
            focusedInput,
            onDatesChange: multiSelect ? onDatesChangeMulti : onDatesChangeSingle,
            isDayBlocked,
            minimumNights: 0,
            onPrevMonthClick: mon => setCurrentMonth(mon),
            onNextMonthClick: mon => setCurrentMonth(mon),
            navPrev: (
                <div className="navigation-button button-prev">
                    <ArrowIcon color="#3d4998" className="arrow-prev" />
                </div>
            ),
            navNext: (
                <div className="navigation-button button-next">
                    <ArrowIcon color="#3d4998" className="arrow-next" />
                </div>
            )
        }),
        [daysData, screenWidth, from, to]
    )

    useEffect(() => {
        if (vesselId) getBookingData()
    }, [currentMonth, vesselId])

    return (
        <div className={`${!yachtPage ? "mb-md-4" : ""} book-calendar`}>
            <div className={`header ${screenWidth < 490 ? "small" : ""}`}></div>
            <DayPickerRangeController {...config} />
            <div className={`footer ${screenWidth < 490 ? "small" : ""}`}></div>
        </div>
    )
}

export default BookCalendar
