import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import formatPrice from "../../utils/priceFormatter"
import PropTypes from "prop-types"

const SingleRouteCard = ({ route, onSelectRoute, selected, setIsModalOpen, setSelectedRoute }) => {
    return (
        <div role="button" className="col" onClick={() => onSelectRoute(route)}>
            <div className={`route-card ${selected === route.id ? "selected" : ""} d-flex align-items-center`}>
                <div className="d-flex flex-column">
                    <div className="route-name mb-3">{route.routeName}</div>
                    <div className="route-price mb-3 d-md-none">+€{formatPrice(route.prices[0].price)}</div>
                    <div className="d-flex mb-2">
                        <div className="route-icon-text me-3">
                            <FontAwesomeIcon icon="fa-regular fa-clock" className="me-1" /> {route.hoursLength} hours
                        </div>
                        {route?.googleMapsUrl && (
                            <div
                                className="route-icon-text"
                                role="button"
                                onClick={() => {
                                    setIsModalOpen(true)
                                    setSelectedRoute(route)
                                }}
                            >
                                <FontAwesomeIcon icon="fa-regular fa-map" className="me-1" />
                                view map
                            </div>
                        )}
                    </div>
                </div>
                <div className="route-price mb-3 d-none d-md-flex justify-content-end">+€{formatPrice(route.prices[0].price)}</div>
            </div>
        </div>
    )
}

SingleRouteCard.propTypes = {
    route: PropTypes.object.isRequired,
    onSelectRoute: PropTypes.func.isRequired,
    selected: PropTypes.string,
    setIsModalOpen: PropTypes.func,
    setSelectedRoute: PropTypes.func
}

export { SingleRouteCard }
