import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import AnchorTop from "../../images/anchor-top.svg"
import AnchorBottom from "../../images/anchor-bottom.svg"
import fetchQuery from "../../utils/fetchQuery"
import Yacht from "./yacht"
import moment from "moment"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

const ChooseYourYacht = ({ data, setData }) => {
    const [yachts, setYachts] = React.useState([])
    const [fetchedPrice, setFetchedPrice] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState(null)

    const allStoredYachts = useStaticQuery(graphql`
        query AllYachtsChoose {
            allYachtsJson {
                nodes {
                    id
                    name
                    link
                    type
                    mainImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `)

    const yachtsData = allStoredYachts.allYachtsJson.nodes

    const getYachts = async () => {
        setErrorMsg(null)
        await fetchQuery({
            method: "GET",
            endPoint: "configuration/Vessel/GetVesselList?status=true",
            successCallback: response => {
                setYachts(response.items)
                if (response.items.length && !data.vesselId) {
                    const storedInfo = yachtsData.find(y => y.name === response.items[0].vesselName)
                    const viper = response.items?.find(i => i.vesselName === "Viper")
                    setData(d => ({
                        ...d,
                        vesselId: viper ? viper.id : response.items[0].id,
                        vesselName: viper ? viper.vesselName : response.items[0].vesselName,
                        vesselCapacity: viper ? viper.capacity : response.items[0].capacity,
                        multiDay: storedInfo?.type !== "main"
                    }))
                }
            },
            failCallback: () => setErrorMsg("Something went wrong. Please reload the page.")
        })
    }

    const getPriceFromYacht = async (id, date) => {
        await fetchQuery({
            method: "GET",
            endPoint: `configuration/Vessel/GetLowestVesselRentalPriceWithinRequestedMonth?vesselId=${id}&date=${date}`,
            successCallback: response => {
                const currentYacht = yachts.find(y => y.id === id)

                if (currentYacht) {
                    const copyYachts = [...yachts]
                    const currentIndex = yachts.indexOf(currentYacht)
                    copyYachts[currentIndex].lowestPrice = response || 0
                    setYachts([...copyYachts])
                }
            }
        })
        setFetchedPrice(true)
    }

    React.useEffect(() => {
        getYachts()
    }, [])

    React.useEffect(() => {
        if (yachts.length && !fetchedPrice) {
            const date = moment().format("YYYY-MM-DD")
            yachts.map(y => getPriceFromYacht(y.id, date))
        }
    }, [yachts, fetchedPrice])

    return (
        <div className="container-xxl flex-grow-1 top-gradient-container">
            <div className="row h-100 position-relative overflow-hidden">
                <img src={AnchorTop} className="overlay-anchor-top-booking d-none d-md-block" alt="Anchor vector" />
                <StaticImage className="d-none d-md-block bg-your-details" src="../../images/water5.png" alt="Water Background" />
                <img src={AnchorBottom} className="overlay-anchor-bottom-booking d-none d-md-block" alt="Anchor bottom vector" />
                <div className="col-12 pt-5 col-md-8 ps-inside z-index-1">
                    <span className="breadcrumbs mb-3">Book your experience &gt; Details &gt; Yacht</span>
                    <h2>Choose your Yacht</h2>
                    <p className="mb-md-4">
                        Select your Yacht of choice to take you on your journey through the white isle. Our fleet has something to
                        offer every group of every size, check out the yacht specs to find out more.
                    </p>
                </div>
                <div className="col-12 col-md-11 ps-inside z-index-1 pb-4">
                    <div className=" row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
                        {yachtsData.map(storedYacht => {
                            const yachtFromBack = yachts.find(y => y.vesselName === storedYacht.name)
                            return yachtFromBack && yachtFromBack.isActive ? (
                                <Yacht
                                    key={yachtFromBack.id}
                                    selected={data.vesselId}
                                    setData={setData}
                                    guests={data.guests}
                                    yachtName={yachtFromBack.vesselName}
                                    yachtsData={yachtsData}
                                    yachtId={yachtFromBack.id}
                                    capacity={yachtFromBack.capacity}
                                    lowestPrice={yachtFromBack?.lowestPrice}
                                />
                            ) : null
                        })}
                        {errorMsg && <p>{errorMsg}</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

ChooseYourYacht.propTypes = {
    data: PropTypes.object.isRequired,
    setData: PropTypes.func.isRequired
}

export default ChooseYourYacht
