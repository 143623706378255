import React from "react"
// import { BcLeafletMap } from "./BcLeafletMap"
const RouteMapModal = ({ setIsOpen, selectedRoute }) => {
    const { googleMapsUrl } = selectedRoute

    return (
        <div className="modal d-block show fade route-modal modal-backdrop">
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header d-flex justify-content-end route-modal-header">
                        <div className="route-modal-header__close" onClick={() => setIsOpen(false)}>
                            close
                            <svg
                                style={{ marginLeft: "15px" }}
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                                    fill="#3D4998"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body">
                        <iframe src={googleMapsUrl} width="100%" style={{ height: "70vh" }}></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
export { RouteMapModal }
