import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import AnchorTop from "../../images/anchor-top.svg"
import AnchorBottom from "../../images/anchor-bottom.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import fetchQuery from "../../utils/fetchQuery"
import formatPrice from "../../utils/priceFormatter"
import PropTypes from "prop-types"
import { SingleRouteCard } from "./SingleRouteCard"
import moment from "moment"

import { RouteMapModal } from "./RouteMapModal"

const SelectRoute = ({ data, setPage, setData, isMobileScreen }) => {
    const [ariaExpanded, setAriaExpanded] = useState(!isMobileScreen)
    const [routes, setRoutes] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedRoute, setSelectedRoute] = useState({})
    const [errorMsg, setErrorMsg] = useState(null)

    const { routeId: selected, routeName, summary, from, to, vesselName } = data

    const oneDay = from?.isSame(to) || !to
    const fromTo = !from
        ? ""
        : oneDay
        ? moment(from).format("Do of MMMM YYYY")
        : `${moment(from).format("Do of MMMM YYYY")} - ${moment(to).format("Do of MMM YYYY")}`
    const salesTax = (summary.boatRentalPrice + summary.fuel) * 0.21
    const totalAmount = summary.boatRentalPrice + summary.fuel + salesTax

    const getRoutes = async () => {
        setErrorMsg(null)
        await fetchQuery({
            method: "GET",
            endPoint: `configuration/Route/GetRouteList?status=true&vesselId=${data.vesselId}`,
            successCallback: response => {
                setRoutes(response.items)
                if (response.items.length) {
                    const { id, routeName, prices } = response.items[0]
                    setData(d => ({
                        ...d,
                        routeName,
                        routeId: id,
                        summary: {
                            ...d.summary,
                            fuel: prices[0].price
                        }
                    }))
                }
            },
            failCallback: () => setErrorMsg("Something went wrong. Please reload the page.")
        })
    }

    useEffect(() => {
        getRoutes()
    }, [])

    const onSelectRoute = route => {
        setData(d => ({
            ...d,
            routeId: route.id,
            routeName: route.routeName,
            summary: {
                ...d.summary,
                fuel: route.prices[0].price
            }
        }))
    }
    return (
        <>
            {isModalOpen && <RouteMapModal setIsOpen={setIsModalOpen} selectedRoute={selectedRoute} />}
            <div className="container-xxl flex-grow-1 top-gradient-container">
                <div className="row h-100 position-relative overflow-hidden">
                    <img src={AnchorTop} className="overlay-anchor-top-booking d-none d-md-block" alt="Anchor vector" />
                    <StaticImage className="d-none d-md-block bg-your-details" src="../../images/water5.png" alt="Water Background" />
                    <img src={AnchorBottom} className="overlay-anchor-bottom-booking d-none d-md-block" alt="Anchor bottom vector" />
                    <div className="col-12 pt-5 col-md-7 ps-inside z-index-1">
                        <span className="breadcrumbs mb-3">Book your experience &gt; Details &gt; Yacht &gt; Date &gt; Route</span>
                        <h2>Select the route</h2>
                        <p className="mb-md-4">
                            Your day, your way! Choose your route of choice around the Island for your journey aboard your very own
                            Maliblue Yacht.
                        </p>
                        <div className="row g-3 row-cols-1 mb-3">
                            {routes
                                .sort((a, b) => a.routeName?.localeCompare(b.routeName))
                                .map(route => (
                                    <SingleRouteCard
                                        key={route.id}
                                        setIsModalOpen={setIsModalOpen}
                                        setSelectedRoute={setSelectedRoute}
                                        selected={selected}
                                        route={route}
                                        onSelectRoute={onSelectRoute}
                                    />
                                ))}
                            {errorMsg && <p>{errorMsg}</p>}
                        </div>
                    </div>
                    <div className="col-12 mb-3 col-md-5 pt-md-5  z-index-1">
                        <div className="expense-card">
                            <div className="expense-header d-flex">
                                <div className="text-purple">Your Experience Summary</div>
                                <div className="ms-auto">
                                    <button
                                        className={`button small d-md-none light-background ${ariaExpanded ? "collapsed" : ""}`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#expense"
                                        aria-expanded={ariaExpanded}
                                        aria-controls="expense"
                                        onClick={() => setAriaExpanded(!ariaExpanded)}
                                    >
                                        {!ariaExpanded ? (
                                            <>
                                                <FontAwesomeIcon fixedWidth className=" " size="1x" icon={["far", "arrow-down"]} />
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon className="" fixedWidth size="1x" icon={["far", "arrow-up"]} />
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div className={`collapse ${!isMobileScreen ? "show" : ""}`} id="expense">
                                <div className="expense-body">
                                    <div className="mb-3">
                                        <div className="d-flex mb-2">
                                            <div className="expense-label">Your yacht</div>
                                            <div
                                                className="ms-auto expense-edit"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setPage("yacht")}
                                            >
                                                Edit
                                            </div>
                                        </div>
                                        <div className="expense-value">Rental of {vesselName}</div>
                                        <div className="border-sub my-3" />
                                    </div>
                                    <div className="mb-2">
                                        <div className="d-flex mb-2">
                                            <div className="expense-label">Your date</div>
                                            <div
                                                className="ms-auto expense-edit"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setPage("date")}
                                            >
                                                Edit
                                            </div>
                                        </div>
                                        <div className="expense-value">{fromTo}</div>
                                        <div className="border-sub my-3" />
                                    </div>
                                    <div className="mb-2">
                                        <div className="d-flex mb-2">
                                            <div className="expense-label">Your route</div>
                                        </div>
                                        <div className="expense-value">{routeName}</div>
                                    </div>
                                </div>
                                <div className="expense-footer">
                                    <div className="d-flex mb-2">
                                        <div className="expense-label-footer">Boat Rental</div>
                                        <div className="ms-auto expense-value-footer">€{formatPrice(summary.boatRentalPrice)}</div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="expense-label-footer">Fuel</div>
                                        <div className="ms-auto expense-value-footer">€{formatPrice(summary.fuel)}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="expense-label-footer">Sales Tax (21%)</div>
                                        <div className="ms-auto expense-value-footer">€{formatPrice(salesTax)}</div>
                                    </div>
                                    <div className="border-total my-3" />
                                    <div className="d-flex mb-2">
                                        <div className="expense-total-label">Total Amount so far</div>
                                        <div className="ms-auto expense-total-value">€{formatPrice(totalAmount)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

SelectRoute.propTypes = {
    data: PropTypes.object.isRequired,
    setPage: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired
}

export default SelectRoute
