import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import LogoTop from "../images/maliblueyachts-top.svg"
import LogoTopLight from "../images/maliblueyachts-top-light.svg"

import AnchorTop from "../images/anchor-top.svg"
import AnchorBottom from "../images/anchor-bottom.svg"
import { StaticImage } from "gatsby-plugin-image"
import Obfuscate from "react-obfuscate"
import Socials from "./socials"
import { BOOKING } from "../utils/constants"

const Header = () => (
    <div className="container-fluid container-xxl">
        <div className="row mb-4 mb-md-0">
            <div className="col text-center p-0">
                <img className="mt-4 mt-md-5" src={LogoTop} height="47.37" alt="Maliblue Yachts" />
            </div>
        </div>
    </div>
)

// #3D4998

const HamburgerIcon = ({ fill }) => (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line style={{ transition: "all .3s" }} y1="1" x2="26" y2="1" stroke={fill} strokeWidth="2" />
        <line style={{ transition: "all .3s" }} y1="11" x2="26" y2="11" stroke={fill} strokeWidth="2" />
        <line style={{ transition: "all .3s" }} y1="21" x2="26" y2="21" stroke={fill} strokeWidth="2" />
    </svg>
)

const NavBar = ({ whiteNav, bookNow = true, image }) => {
    const [colorChange, setColorchange] = useState(false)
    const [isHoveredMenu, setIsHoveredMenu] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [login, setLogin] = useState(false)

    const onLogout = () => {
        localStorage.removeItem("token")
        setLogin(undefined)
        navigate("/")
    }

    useEffect(() => {
        if (typeof window !== "undefined" && !!window?.localStorage.token) {
            setLogin(true)
        }
    }, [])

    useEffect(() => {
        const changeNavbarColor = () => {
            if (window?.scrollY >= 1) {
                setColorchange(true)
            } else {
                setColorchange(false)
            }
        }

        typeof window !== "undefined" && window?.addEventListener("scroll", changeNavbarColor)

        return () => typeof window !== "undefined" && window?.removeEventListener("scroll", changeNavbarColor)
    }, [])

    return (
        <nav
            style={isOpen ? { zIndex: 1036 } : undefined}
            className={`navbar navbar-light sticky-top py-md-3 ${image ? "transparent" : ""}  ${
                colorChange ? `bg-white` : `bg-blue`
            } ${whiteNav ? `bg-white` : ``}`}
        >
            <div className="container-fluid d-flex">
                <Link className="navbar-brand abs" to="/">
                    <img className="" src={LogoTop} alt="Maliblue Yachts" />
                </Link>
                <div className="ms-auto d-flex">
                    {bookNow && (
                        <Link
                            to={`${BOOKING ? "/book" : "/book-an-experience"}`}
                            className="button fill light-background me-3 d-none d-md-inline-block"
                            type="button"
                        >
                            book now
                        </Link>
                    )}
                    <button
                        onClick={() => setIsOpen(true)}
                        className=" button fill light-background "
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar"
                        aria-controls="offcanvasNavbar"
                        onMouseEnter={() => setIsHoveredMenu(true)}
                        onMouseLeave={() => setIsHoveredMenu(false)}
                    >
                        <HamburgerIcon fill={isHoveredMenu ? "#fff" : "#3D4998"} />
                        {/* <FontAwesomeIcon fixedWidth className="d-md-none toggler" size="1x" icon={["far", "bars"]} />
                        <FontAwesomeIcon fixedWidth className="d-none d-md-block toggler" size="1x" icon={["far", "bars"]} /> */}
                    </button>
                </div>

                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header position-relative">
                        <div className="text-center flex-grow-1 d-block d-md-none">
                            <img className="logo-menu" src={LogoTopLight} height="24" alt="Maliblue Yachts" />
                        </div>
                        <div className="ms-auto d-flex" data-bs-dismiss="offcanvas" aria-label="Close">
                            <Link
                                to={`${BOOKING ? "/book" : "/book-an-experience"}`}
                                className="button fill dark me-3 d-none d-md-inline-block"
                                type="button"
                            >
                                book now
                            </Link>

                            <button
                                onClick={() => setIsOpen(false)}
                                className=" button fill dark "
                                type="button"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            >
                                <FontAwesomeIcon fixedWidth size="1x" icon="fa-regular fa-xmark" />
                            </button>
                        </div>
                    </div>
                    <div className="offcanvas-body">
                        <div className="navbar-nav flex-grow-1 text-center h-100 position-relative">
                            <div className="text-center my-2 my-lg-3 my-md-4">
                                <img src={AnchorTop} className="anchor-top" alt="Anchor vector" />
                            </div>

                            <div className="mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                <Link className="left menu" to="/" activeClassName="active">
                                    Home
                                </Link>
                            </div>
                            <div className="mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                <Link
                                    className="left menu"
                                    to={`${BOOKING ? "/book" : "/book-an-experience"}`}
                                    activeClassName="active"
                                >
                                    Book an experience
                                </Link>
                            </div>
                            <div className="mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                <Link className="left menu" to="/fleet/" activeClassName="active">
                                    Our fleet
                                </Link>
                            </div>
                            <div className="mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                <Link className="left menu" to="/about/" activeClassName="active">
                                    About us
                                </Link>
                            </div>

                            <div className="mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                <Link className="left menu" to="/contact" activeClassName="active">
                                    Contact
                                </Link>
                            </div>
                            <div className="text-center mt-2 mt-lg-3 mt-md-4">
                                <img src={AnchorBottom} className="anchor-bottom" alt="Anchor vector" />
                            </div>
                            <div className="mb-2 d-flex h-100 flex-column">
                                <div className="mt-2 admin-navigation">
                                    <div className={`${!login ? "d-none" : "d-block"}`}>
                                        <div data-bs-dismiss="offcanvas" aria-label="Close">
                                            <Link className="left menu" to="/planning" activeClassName="active">
                                                Planning
                                            </Link>
                                        </div>
                                        <div data-bs-dismiss="offcanvas" aria-label="Close">
                                            <Link className="left menu" to="/booking" activeClassName="active">
                                                Bookings
                                            </Link>
                                        </div>
                                        <div data-bs-dismiss="offcanvas" aria-label="Close">
                                            <Link className="left menu" to="/configuration" activeClassName="active">
                                                Configuration
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {BOOKING &&
                                (!login ? (
                                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                                        <Link
                                            className="user-login-menu text-white text-decoration-none my-3"
                                            to="/signin"
                                            activeClassName="active"
                                        >
                                            Login as admin
                                        </Link>
                                    </div>
                                ) : (
                                    <div
                                        role="button"
                                        onClick={onLogout}
                                        className="user-login-menu text-white text-decoration-none my-3"
                                    >
                                        Logout
                                    </div>
                                ))}
                        </div>
                    </div>
                    {!BOOKING && (
                        <div className="offcanvas-footer d">
                            <div className="d-grid">
                                <StaticImage className="offcanvas-background-image" src="../images/waves.png" alt="waves" />
                                <div className="offcanvas-footer-body d-flex align-items-center">
                                    <div className="container">
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <div className="footer-title">Get in touch</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="row">
                                                    {/* <div className="col-md-6 mb-2 mb-md-0">
                                                    <Obfuscate
                                                        className="text-decoration-none left  text-white"
                                                        tel="+34 123 123 123"
                                                    />
                                                </div> */}
                                                    <div className="col-md-6 mb-2 mb-md-0">
                                                        <Obfuscate
                                                            className="text-decoration-none left  text-white"
                                                            email="hello@maliblueyachts.com"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5 mt-1 mt-md-0 d-flex justify-content-md-end">
                                                <Socials me="me-2 me-md-3 text-white" mail={false} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    )
}

export { Header, NavBar }
