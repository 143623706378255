import React, { useContext } from "react"
import { StripeContext } from "../../pages/book"

const PayButton = ({ onPay, isLoadingPayment }) => {
    const { stripe, elements } = useContext(StripeContext)
    return (
        <button disabled={isLoadingPayment} className="ms-auto button light" onClick={() => onPay(stripe, elements)}>
            <span className=" d-block">{isLoadingPayment ? "processing..." : "pay now"}</span>
        </button>
    )
}

export default PayButton
