import { Link } from "gatsby"
import * as React from "react"
import Obfuscate from "react-obfuscate"
import MbyByCp from "../images/mbycp.svg"
import CandyPantsLogo from "../images/candypants.svg"
import Socials from "./socials"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import PayButton from "./booking/payButton"
import { BOOKING } from "../utils/constants"

const MiniFooter = () => (
    <div className="container-fluid footer">
        <div className="container-xxl h-100">
            <div className="row h-100 d-flex justify-content-center">
                <div className="col-12 col-md-4 d-sm-none d-md-block"></div>
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center py-5">
                    <img src={MbyByCp} alt="Anchor vector" />
                </div>
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center pt-3 pb-5 ">
                    <Socials mail={true} me="me-3" />
                </div>
            </div>
        </div>
    </div>
)

const Footer = () => (
    <div className="footer">
        <div className="container-xxl">
            <div className="row ">
                <div className="col-12 pt-3 pb-2 col-md-6 col-lg-3 py-lg-5">
                    <div className="footer-title">Site Map</div>
                    <div className="mb-2">
                        <Link className="left" to={BOOKING ? "/book" : "/book-an-experience"}>
                            Book an experience
                        </Link>
                    </div>
                    <div className="mb-2">
                        <Link className="left" to="/fleet">
                            Our Fleet
                        </Link>
                    </div>
                    <div className="mb-2">
                        <Link className="left" to="/about">
                            About us
                        </Link>
                    </div>
                    <div>
                        <Link className="left" to="/contact">
                            Contact us
                        </Link>
                    </div>
                </div>
                <div className="col-12 pt-3 pb-2 col-md-6 col-lg-3 py-lg-5">
                    <div className="footer-title">Get in touch</div>
                    {/* <div className="mb-2">
                        <Obfuscate className="text-decoration-none" tel="+34 123 123 123" />
                    </div> */}
                    <div className="mb-3">
                        <Obfuscate className="text-decoration-none" email="hello@maliblueyachts.com" />
                    </div>
                    <div>
                        <Socials mail={false} me="me-2" />
                    </div>
                </div>
                <div className="col-12 pt-3 pb-2 col-md-6 pb-md-3 col-lg-3 py-lg-5">
                    <div className="footer-title">
                        <img src={CandyPantsLogo} width={121} alt="CANDYPANTS" />
                    </div>
                    <div className="mb-2">
                        <p>
                            Maliblue Yachts has been brought to you by Candypants. With events across Europe, the Middle East and
                            America Candypants creates experience led events that have broken boundaries worldwide.
                        </p>
                    </div>
                    <div>
                        <a className="left preline-light" href="https://candypants.events/" target="_blank" rel="noreferrer noopener">
                            Visit the site <FontAwesomeIcon fixedWidth size="1x" icon={["far", "arrow-right"]} />
                        </a>
                    </div>
                </div>
                <div className="col-12 pt-3 pb-3 col-md-6 col-lg-3 py-lg-5">
                    <div className="footer-title text-center">
                        <img src={MbyByCp} alt="Anchor vector" />
                    </div>
                </div>
            </div>
        </div>
    </div>
)

const FooterBooking = ({ page, steps, foodExtras, nextPage, prevPage, onPay, clientSecret, isLoadingPayment }) => {
    const isPaymentPage = page === "payment"
    const index = steps.findIndex(e => e.urlName === page)

    let prevText = steps[index - 1]?.name
    let nextText = steps[index + 1]?.name

    switch (page) {
        case steps[4].urlName:
            if (!foodExtras.length) nextText = steps[index + 2].name
            break
        case steps[6].urlName:
            if (!foodExtras.length) prevText = steps[index - 2].name
            break
    }

    return (
        <div className="bg-purple sales-footer flex-shrink-0 w-100">
            <div className="container-xxl">
                <div className="row ">
                    {page !== "details" ? (
                        <div className="col d-flex">
                            <button onClick={prevPage} className="button fill dark">
                                <span className="d-md-none">back</span>
                                <span className="d-none d-md-block">back: {prevText}</span>
                            </button>
                        </div>
                    ) : null}
                    <div className="col d-flex">
                        {isPaymentPage ? (
                            clientSecret ? (
                                <PayButton onPay={onPay} isLoadingPayment={isLoadingPayment} />
                            ) : null
                        ) : (
                            <button onClick={nextPage} className="ms-auto button fill dark">
                                <span className="d-md-none">next</span>
                                <span className="d-none d-md-block">next: {nextText}</span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

FooterBooking.propTypes = {
    page: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
    foodExtras: PropTypes.array.isRequired,
    nextPage: PropTypes.func.isRequired,
    prevPage: PropTypes.func.isRequired,
    onPay: PropTypes.func.isRequired
}

export { MiniFooter, Footer, FooterBooking }
